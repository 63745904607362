.bootstrap-dropdown {
  font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  letter-spacing: 0.045rem;
  min-height: 90px;
  max-height: 90px;
  /*overflow: scroll;	*/
}

.bootstrap-dropdown .dropdown-trigger {
  border-radius: 0.25rem;
  width: 100%;
  height: 90px;
}

.bootstrap-dropdown .dropdown {
  width: 100%;
}

.bootstrap-dropdown .dropdown .dropdown-trigger.arrow.bottom:after {
  content: '';
  color: hsl(0, 0%, 80%);
}

.bootstrap-dropdown .dropdown .dropdown-trigger.arrow.top:after {
  content: '';
}

.bootstrap-dropdown .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-dropdown .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-dropdown .root {
  /*background-color: red;*/

  padding: 0px;
  margin: 0px;
}

.bootstrap-dropdown .tag-item:last-child {
  margin-right: 0px;
}

.bootstrap-dropdown .tag-item {
  display: inline-block;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 0px;
  width: 95%;
  padding-left: 6px;
}

.bootstrap-dropdown .tag-item input {
  width: 100%;
  border: none;
  letter-spacing: 0.045rem;
  font-size: 14px;
  font-weight: 400;
  color: hsl(0, 0%, 50%);
}

.bootstrap-dropdown .dropdown-content {
  top: 90px;
  z-index: 9 !important;
  width: 100%;
  max-height: 500px;
  overflow: scroll;
}
