@import '../../../styles/variables';

.view {
  .view-dashboard {
    height: 80vh;
  }
}

.income-card {
  .card .card-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $md-blue-grey-50;
    svg {
      transform: translate(0, -2px);
    }
  }
}
.map-buttons {
  position: absolute;
  right: 0;
  top: -27px;
  z-index: 999;
}
