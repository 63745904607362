@import '../../../styles/variables';

.view {
  .view-dashboard .metric-card {
    .card + .card .card-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid $md-blue-grey-50;
      svg {
        transform: translate(0, -2px);
      }
    }
    .card + .card .card-body:last-child {
      border: 0;
    }
  }
}

.income-card {
  .card .card-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $md-blue-grey-50;
    svg {
      transform: translate(0, -2px);
    }
  }
}
