.advisor_card_selected {
  border: var(--brand-ninth) 2px solid;
  box-shadow: 0 0 10px var(--brand-second-b);
}

.advisor_card_description {
  min-height: 4vw;
}

.recommendation_high {
  border-left: 3px solid var(--brand-third);
}

.recommendation_low {
  border-left: 3px solid var(--brand-second-b);
}
