/* CSS Document */
.view_cont {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0;
  /*background-color:#0d42b2;*/
}
.view_c {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.pre {
  display: none;
}

.show_mob {
  display: none;
}
@media (max-width: 820px) {
  .hide_mob {
    display: none;
  }
  .show_mob {
    display: block;
  }
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  /* background: url('https://www.mofiler.io/wp-content/themes/icos/assets/images/header-overlay.png')
     0 0 no-repeat; */
  background-size: contain, cover;
}

.wave2 {
  height: 45%;
  position: absolute;
  bottom: 41%;
  z-index: 2;
}
.wave3 {
  height: 42%;
  position: absolute;
  bottom: 22%;
  z-index: 3;
}
.wave4 {
  height: 34%;
  position: absolute;
  bottom: 0;
  z-index: 4;
}

@media (max-width: 820px) {
  .wave2 {
    height: 45%;
    position: absolute;
    bottom: 38%;
    z-index: 2;
  }
  .wave3 {
    height: 47%;
    position: absolute;
    bottom: 18%;
    z-index: 3;
  }
  .wave4 {
    height: 34%;
    position: absolute;
    bottom: 0;
    z-index: 4;
  }
}

.title_color {
  color: #4d4d4d;
}
.text_color {
  color: #1a1a1a;
}
.text_3_color {
  color: #333;
}
.text_6_color {
  color: #666;
}

.m_10 {
  margin-top: 10px;
}
.m_12 {
  margin-top: 12px;
}
.m_15 {
  margin-top: 15px;
}
.m_20 {
  margin-top: 20px;
}
.m_25 {
  margin-top: 25px;
}

.main_window {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 820px;
  min-height: 460px;
  border-radius: 9px;
  background-color: #fff;
}
.main_window_big {
  height: auto !important;
}

.main_window_popup {
  height: auto !important;
  width: auto !important;
}

.main_common,
.main_data,
.main_graphic {
  position: relative;
  display: flex;
  justify-content: center;
  width: 700px;
}

.main_os {
  justify-content: space-between;
  width: 505px;
}
.main_skip {
  position: absolute;
  right: 60px;
  top: 20px;
}

.main_logo,
.main_status {
  position: relative;
  display: flex;
  width: 700px;
}
.main_status {
  justify-content: center;
  margin-bottom: 40px;
}
.main_data {
  justify-content: space-between;
  min-height: 400px;
}
.main_code {
  justify-content: flex-start;
  flex-flow: column;
}
.main_graphic {
  width: 100%;
  height: 180px;
  background-color: #0087cc;
  background-size: 100% 100%;
  background-image: url(./img/mof_ob_page2_illus_bg_desktop.png);
}
.data_col {
  display: flex;
  flex-flow: column;
  width: 340px;
}

.data_col_big {
  width: 100% !important;
}

@media (max-width: 820px) {
  body {
    height: auto;
    background: transparent;
  }

  .main_window {
    width: 100%;
    height: auto;
    /* border-radius: 0; */
  }
  .main_status {
    width: 100%;
    margin-top: 3vw;
    margin-bottom: 8vw;
  }
  .data_col,
  .main_common {
    width: 92%;
  }

  .data_col_big {
    width: 92% !important;
  }

  .main_data {
    flex-flow: column;
    align-items: center;
    width: 100vw;
    height: auto;
  }

  .main_logo {
    display: none;
  }
  .main_graphic,
  .main_os {
    width: 100%;
    height: 82.1vw;
    background-image: url(./img/mof_ob_page2_illus_bg_mobile.png);
  }
}

/*    FORM SIGNUP   */

.form {
  min-height: auto;
  height: auto;
}

.bt_common {
  /* display: flex; */
  justify-content: center;
  border-radius: 6px;
  /* background: linear-gradient(90deg, #1dabf2, #43ddf8); */
  height: 50px;
}
.label_common {
  margin: 10px 0;
}
.text_check {
  text-align: center;
  margin: 10px 0 0 4px;
  padding-top: 50px;
  cursor: pointer;
  line-height: 10px;
}
.form_item {
  width: 98%;
  padding: 5px 0;
  padding-left: 2%;
  background: transparent;
  border: none;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
}
.form_item::placeholder {
  font-weight: normal;
  color: #999;
}
.form_item:nth-child(5) {
  margin-bottom: 10px;
}
.form_bt {
  width: 100%;
  border: none;
  cursor: pointer;
}
.bt_steps {
  width: auto;
  padding: 0 100px;
}
.form_agree {
  display: flex;
  justify-content: center;
  width: 100%;
}
.form_check {
  width: 15px;
  height: 15px;
}
@media (max-width: 820px) {
  .form {
    margin-top: 1vw;
  }
  .form_bt {
    margin-top: 4.76vw;
  }
  .form_item {
    padding: 2vw 0;
    margin-bottom: 4vw;
  }
  .form_item:nth-child(5) {
    margin-bottom: 2vw;
  }
  .bt_common {
    border-radius: 1.5vw;
  }
  .label_common {
    margin: 3.5vw 0;
  }
  .form_check {
    width: 4.4vw;
    height: 4.4vw;
  }
  .bt_steps {
    width: 100%;
    padding: 0;
  }
  .signup_text {
    margin-top: 2vw;
    margin-bottom: -1vw;
  }
}

/*   STEP 1   */

.step1_img {
  opacity: 0;
  width: 100%;
  height: 100%;
  background-image: url(./img/mof_ob_page2_illus_desktop.png);
  background-size: 100% 100%;
  animation: fadeIn 1s linear 0.3s;
  animation-fill-mode: forwards;
}
@media (max-width: 820px) {
  .step1_img {
    background-image: url(./img/mof_ob_page2_illus_mobile.png);
  }
}

/*  STEP 2  A - OS  */

.os_common {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  flex-direction: column-reverse;
  position: relative;
  width: 208px;
  height: 198px;
  border-radius: 12px;
  background-color: #0087cc;
  background-size: 100% 100%;
}
.os_and {
  background-image: url(./img/mof_bg_and.png);
}
.os_ios {
  background-image: url(./img/mof_bg_ios.png);
}
.os_ico {
  opacity: 0;
  margin-top: 45px;
}
.os_ico_a0 {
  animation: fadeInFromDown 0.5s ease-in-out 0.3s;
  animation-fill-mode: forwards;
}
.os_ico_a1 {
  animation: fadeInFromDown 0.5s ease-in-out 0.5s;
  animation-fill-mode: forwards;
}
.os_bt {
  border-radius: 0 0 12px 12px;
}
@media (max-width: 820px) {
  .os_common {
    width: 49.8%;
    height: 100%;
    border-radius: 0;
  }
  .main_os {
    background: transparent;
  }
  .os_ico {
    width: 24.2vw;
    height: 24.2vw;
    margin-top: 24vw;
  }
  .os_bt {
    border-radius: 0;
  }
}

/*   STEP  2  - B  */

.codes_title_container {
  display: flex;
  align-items: flex-end;
}
.bt_copy {
  display: flex;
  padding: 3px 11px 4px 11px;
  background-color: #1eacf2;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
}
.code_box {
  width: 100%;
  height: 130px;
  border: 1px solid #d8dadb;
  padding: 13px;
}

.code_img {
  box-shadow: 1px 1px 2px #ccc;
  width: 100%;
}
.title_codes_type {
  margin-left: 25px;
}

button.title_codes_type {
  text-decoration: none;
  background: none;
  border: 0;
  cursor: pointer;
}

@media (max-width: 820px) {
  .bt_copy {
    display: flex;
    padding: 1.42vw 5.23vw 1.9vw 5.23vw;
    margin-left: 4.76vw;
  }
  .code_box {
    height: 40vw;
  }
  .title_codes_type {
    margin-left: 15px;
  }
  .title_codes_type_2 {
    width: 45%;
  }
}

/*   STEP  3 */

.code_box_big {
  height: 332px;
}
.code_sticker {
  opacity: 0;
  position: absolute;
  width: 135px;
  height: 152px;
  top: -40px;
  right: -30px;
  background-image: url(./img/mofi_cheers_desktop.gif);
  background-size: 100% 100%;

  animation: fadeInFromDown 0.5s ease-in-out 0.3s;
  animation-fill-mode: forwards;
}

@media (max-width: 820px) {
  .code_box_big {
    height: 116vw;
  }
  .code_sticker {
    width: 41.19vw;
    height: 32.62vw;
    top: -17vw;
    right: -3vw;
    background-image: url(./img/mofi_cheers_mob.gif);
  }
}

@media (max-width: 820px) {
  .m_10 {
    margin-top: 2.38vw;
  }
  .m_12 {
    margin-top: 2vw;
  }
  .m_15,
  .m_15_mob {
    margin-top: 3.57vw;
  }
  .m_20 {
    margin-top: 4.76vw;
  }
  .m_25,
  .m_25_mob {
    margin-top: 5.95vw;
  }
  .mb_25_mob {
    margin-bottom: 5.95vw;
  }
  .m_65_mob {
    margin-top: 15.5vw;
  }
}

/*   ANIMS   */

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
@keyframes fadeInFromDown {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  60% {
    opacity: 1;
    transform: translateY(-15px);
  }
  80% {
    opacity: 1;
    transform: translateY(8px);
  }
  90% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.main_signin {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.logo_sign {
  align-items: center;
  width: 100%;
}
.img_logo_sign {
  /* width: 50%; */
}
@media (max-width: 820px) {
  .main_signin {
    width: 100%;
  }
  .img_logo_sign {
    /* width: 47.6vw; */
    /* height: 63.33vw; */
  }
}
/* CSS Document */

.status_number {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  background-color: #c7c7c7;
  color: #fff;
  border-radius: 100%;
}
.st_num {
  position: relative;
  top: -0.5px;
  left: 0.3px;
}
.status_active {
  background-color: #046fc7;
}
.status_line {
  display: flex;
  position: relative;
  width: 132px;
  height: 1px;
  margin: 9px 12px 0 12px;
  border-bottom: 2px dotted #c7c7c7;
}
.step_item {
  position: relative;
}
.status_label {
  position: absolute;
  text-align: center;
  width: 120px;
  left: -50px;
  padding-top: 4px;
}
@media (max-width: 820px) {
  .status_number {
    width: 7.14vw;
    height: 7.14vw;
  }
  .st_num {
    top: 0;
    left: 0;
  }
  .status_line {
    width: 22vw;
    margin: 3.5vw 1.9vw 0 1.9vw;
    border-bottom: 0.6vw dotted #c7c7c7;
  }
  .status_label {
    width: 33.5vw;
    left: -13vw;
    padding-top: 1.5vw;
  }
}

.title_color {
  color: #4d4d4d;
}

.title_big {
  /*font-family: titillium-light;*/
  font-size: 3vw;
  letter-spacing: 0.2vw;
  line-height: 1.3;
}
.title_med {
  /*font-family: titillium-light;*/
  font-size: 23px;
  letter-spacing: 1.2px;
}
.text_plain {
  /*font-family: roboto-regular;*/
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1.2px;
}
.text_small {
  /*font-family: roboto-regular;*/
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
}
.text_form {
  /*font-family: titillium-light;*/
  font-size: 16px;
  letter-spacing: 1px;
}
.text_label {
  /*font-family: roboto-regular;*/
  font-size: 16px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: bold;
}
.text_steps {
  /*font-family: roboto-regular;*/
  font-size: 14px;
  font-weight: bold;
}
.text_steps_label {
  /*font-family: roboto-regular;*/
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.title_codes {
  /*font-family: roboto-regular;*/
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.bt_copy {
  /*font-family: roboto-regular;*/
  font-size: 12px;
  letter-spacing: 0.7px;
  font-weight: bold;
}

@media (max-width: 820px) {
  .title_big {
    font-size: 10vw;
    letter-spacing: 0.2vw;
  }
  .title_med {
    font-size: 6.8vw;
    letter-spacing: 0.12vw;
  }
  .text_plain {
    font-size: 4.2vw;
    line-height: 6.6vw;
    letter-spacing: 0.24vw;
  }
  .text_form {
    font-size: 4.7vw;
    letter-spacing: 0.2vw;
  }
  .text_label {
    font-size: 4.5vw;
    letter-spacing: 0.2vw;
  }
  .text_small {
    font-size: 3.8vw;
    letter-spacing: 0.05vw;
  }
  .text_steps {
    font-size: 4.8vw;
  }
  .text_steps_label {
    font-size: 3.2vw;
    letter-spacing: 0.025vw;
  }
  .title_codes {
    font-size: 4.28vw;
    letter-spacing: 0.03vw;
  }
  .bt_copy {
    font-size: 3.57vw;
    letter-spacing: 0.025vw;
  }
}
