@import '../../../styles/variables';

.view {
  .view-compare .metric-card {
    min-width: 24.5%;

    .card .card-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid $md-blue-grey-50;
      svg {
        transform: translate(0, -2px);
      }
    }
    .card + .card .card-body:last-child {
      border: 0;
    }
  }
  .view-columns {
    display: flex;
    justify-content: space-between;
  }
}
